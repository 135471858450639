import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import hark from 'hark';
import WaveAnimation from './components/Waveform.gif';
import { transcribeAudio, sendResultForTTSPython, sendResultForTTS, splitInputPerSentence, numberToIndonesian, replaceNumbersWithIndonesian, generateShortUuid, checkCutandStop } from './utils/utils';
import 'react-phone-input-2/lib/style.css';
import './App.css';
import { Event } from "@sentry/react";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const RecorderComponent: React.FC = () => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordState, setRecordState] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [longLoadText, setLongLoadText] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const abortControllerRef = useRef<AbortController | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const speechEventsRef = useRef<any>(null);
  const ttsAudioRef = useRef<HTMLAudioElement | null>(null);
  const isProcessingTTS = useRef<boolean>(false);
  const isProcessingRef = useRef<boolean>(false);
  const isRegistering = useRef<boolean>(false);
  const isCut = useRef<boolean>(false);
  const isStopped = useRef<boolean>(false);
  const isInitialized = useRef<boolean>(false);
  const isInitialRender = useRef<boolean>(true);
  const isSelesai = useRef<boolean>(false);
  const sessionUUID = useRef<string>(`Vcall - ${generateShortUuid()}`)

  interface RegistrationInformation {
    Nama?: string;
    NomorTelfon?: string;
    Email?: string;
    NamaDokter?: string;
    Spesialisasi?: string;
    TanggalJanjiTemu?: string;  // Assuming this is a string representing a date
    TanggalRelatif?: string;
    WaktuJanjiTemu?: string;    // Assuming this is a string representing time
    AlasanJanjiTemu?: string;
  }

  interface ChatbotInputData {
    user_input: string;
    stored_info: Record<string, any>;
    all_chat_history: any[];
    registration_list: any[];
    chat_state: string;
    sender_id?: string;
    sender_name?: string;
  }

  const storedInfo = useRef<Record<string, any>>({});
  const allChatHistory = useRef<any[]>([])
  const registrationList = useRef<any[]>([])
  const chatState = useRef<string>("")
  const registrationInformation = useRef<RegistrationInformation>({});

  type ChatMessage = { user?: string; client?: string };
  const chatHistory = useRef<ChatMessage[]>([]);

  const sessionId = uuidv4();
  const userLog = useRef<string>(`UUID: ${sessionId}\n------------------------------------------------------\n`);

  const newAbortController = new AbortController();
  abortControllerRef.current = newAbortController;
  const abortSignal = newAbortController.signal

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return; // Skip the effect on the initial render
    }
    if (recordState) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [recordState]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      sendLogInChunks();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('MediaDevices API or getUserMedia is not supported in this browser.');
      return;
    }

    if (recordState) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true
          }
        });
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunksRef.current = [];
        const options: hark.Option = {
          interval: 30,
          threshold: -40
        };
        speechEventsRef.current = hark(stream, options);

        if (!isInitialized.current) {
          isProcessingRef.current = true
          await playAudio("Greetings")
          isProcessingRef.current = false
          isInitialized.current = true
        }
        console.log('Starting recording...');
        speechEventsRef.current.on('speaking', () => {
          speechEventsRef.current.setThreshold(-35);
          if (!isProcessingRef.current) {
            if (ttsAudioRef.current) {
              ttsAudioRef.current.pause(); // Stop TTS audio playback
              ttsAudioRef.current = null;
            }
            isCut.current = true;
            console.log('Speaking detected');
            if (mediaRecorderRef.current?.state !== 'recording' && !isProcessingRef.current) {
              mediaRecorderRef.current?.start();
            }
          } else {
            console.log('Ongoing process...');
          }
        });

        // speechEventsRef.current.on('volume_change', async (volume: any) => {
        //   if (volume >= -40) { console.log(`Volume: ${volume}`) }
        // })

        speechEventsRef.current.on('stopped_speaking', async () => {
          isCut.current = false;
          console.log("Start waiting...")
          await new Promise(resolve => setTimeout(resolve, 1000));
          if (!isProcessingRef.current && mediaRecorderRef.current?.state === 'recording' && !isCut.current) {
            console.log('Stopped speaking');
            mediaRecorderRef.current.stop();
          }
        });

        mediaRecorderRef.current.ondataavailable = (e: BlobEvent) => {
          audioChunksRef.current.push(e.data);
        };

        mediaRecorderRef.current.onstop = async () => {
          waitGeneric()
          speechEventsRef.current.setThreshold(-30);
          if (isProcessingRef.current) return;
          isProcessingRef.current = true;
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
          audioChunksRef.current = [];
          try {
            setIsLoading(true);
            let startTime, endTime;

            startTime = performance.now();
            const transcriptionResult = await transcribeAudio(audioBlob, abortSignal, isCut, isStopped);
            endTime = performance.now();
            console.log(`sendRecordingForTranscription took ${(endTime - startTime) / 1000} seconds.`);
            console.log(`Transcription result: ${transcriptionResult}`)
            chatHistory.current.push({ user: `${transcriptionResult}` })
            userLog.current += `User: ${transcriptionResult}\n`
            await processInput(transcriptionResult)

          } catch (error) {
            console.error('Process was aborted or an error occurred:', error);
          } finally {
            if (isRecording) {
              startRecording(); // Restart recording if still in recording mode
            } else {
              setIsRecording(false);
              abortControllerRef.current = null;
            }
            if (isSelesai.current) {
              setRecordState(false);
            }
          }
        };
        setIsRecording(true);
      } catch (error) {
        console.error('Error starting recording:', error);
      }
    }
  };

  async function processInput(transcriptionResult: string) {
    const userInput = transcriptionResult
    const requestData: ChatbotInputData = {
      user_input: userInput,
      stored_info: storedInfo.current,
      all_chat_history: allChatHistory.current,
      registration_list: registrationList.current,
      chat_state: chatState.current,
      sender_id: sessionUUID.current
    };
    try {
      const response = await axios.post('https://api.testapp-demo.xyz/process_input', requestData, { signal: abortSignal });
      const data = response.data;
      const clientResponse = data.response;
      const storedInfoOutput = data.stored_info;
      const allChatHistoryOutput = data.all_chat_history;
      const registrationListOutput = data.registration_list;
      const chatStateOutput = data.chat_state;

      storedInfo.current = {
        ...storedInfo.current,
        Nama: storedInfoOutput.Nama ?? storedInfo.current.Nama,
        NomorTelfon: storedInfoOutput.NomorTelfon ?? storedInfo.current.NomorTelfon,
        Email: storedInfoOutput.Email ?? storedInfo.current.Email,
        NamaDokter: storedInfoOutput.NamaDokter ?? storedInfo.current.NamaDokter,
        Spesialisasi: storedInfoOutput.Spesialisasi ?? storedInfo.current.Spesialisasi,
        TanggalJanjiTemu: storedInfoOutput.TanggalJanjiTemu ?? storedInfo.current.TanggalJanjiTemu,
        HariJanjiTemu: storedInfoOutput.HariJanjiTemu ?? storedInfo.current.HariJanjiTemu,
        TanggalRelatif: storedInfoOutput.TanggalRelatif ?? storedInfo.current.TanggalRelatif,
        WaktuJanjiTemu: storedInfoOutput.WaktuJanjiTemu ?? storedInfo.current.WaktuJanjiTemu,
        AlasanJanjiTemu: storedInfoOutput.AlasanJanjiTemu ?? storedInfo.current.AlasanJanjiTemu,
        ChangeInfo: storedInfoOutput.ChangeInfo ?? storedInfo.current.ChangeInfo
      };

      allChatHistory.current = allChatHistoryOutput
      registrationList.current = [...registrationListOutput];
      chatState.current = chatStateOutput

      const category = data.category
      if (category === "Selesai") {
        await playAudio("Selesai")
      } else if (category === "IntensiBertanya") {
        await playAudio("Question")
      } else {
        await handleTTSResponse(clientResponse, abortSignal);
      }

      return clientResponse
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Response data:', error.response.data);
      } else {
        console.error('Error:', error);
      }
    }
  }

  function sendLogInChunks(logString: string = userLog.current, uniqueId: string = sessionId): void {
    const sendCustomEvent = () => {
      const event: Event = {
        event_id: uniqueId, // Replace with your unique ID if needed
        logentry: {
          message: logString
        },
        level: 'log', // Can be 'info', 'warning', 'error', etc.
      };
      // Sentry.captureEvent(event);
      console.log(`Sentry message sent:\n${logString}`)
    };

    sendCustomEvent()
  }

  function waitGeneric() {
    const options = [
      { text: "Mohon ditunggu sebentar...", audio: "/audio/Wait1.mp3" },
      { text: "Sebentar dulu ya...", audio: "/audio/Wait2.mp3" },
      { text: "Oke, sebentar dulu...", audio: "/audio/Wait3.mp3" },
      { text: "Baik, mohon ditunggu dulu...", audio: "/audio/Wait4.mp3" }
    ];
    const selectedOption = options[Math.floor(Math.random() * options.length)];
    setLongLoadText(selectedOption.text);

    const audioContext = new (window.AudioContext)();
    const source = audioContext.createBufferSource();
    fetch(selectedOption.audio)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        source.start(0);
        setIsPlaying(true)
        source.onended = () => {
          setIsPlaying(false)
        }
      })
      .catch(error => console.error('Error loading audio file:', error));
  }

  function playAudio(type: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (checkCutandStop(isCut, isStopped)) {
        return reject(new Error('Function stops because of isCut or isStopped'));
      }

      let audio = "";
      let text = "";
      switch (type) {
        case "Greetings":
          audio = "/audio/Greetings.mp3";
          text = "Selamat datang di demo doctor-vcall. Apa ada yang bisa saya bantu?";
          break;
        case "Question":
          audio = "/audio/Question.mp3";
          text = "Baik, apa yang ingin anda tanyakan?";
          break;
        case "Selesai":
          audio = "/audio/Selesai.mp3";
          text = "Terima kasih untuk menggunakan layanan doctor-vcall. Sampai jumpa lagi.";
          break;
      }

      chatHistory.current.push({ client: text });
      const audioContext = new (window.AudioContext)();
      const source = audioContext.createBufferSource();
      fetch(audio)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
        .then(audioBuffer => {
          source.buffer = audioBuffer;
          source.connect(audioContext.destination);
          source.start(0);
          setText(text);
          setIsLoading(false);
          setIsPlaying(true);
          source.onended = () => {
            setIsPlaying(false);
            if (type === "Selesai") {
              isSelesai.current = true;
            }
            resolve();
          };
        })
        .catch(error => reject(error));
    });
  }

  const stopRecording = (cleanup: boolean = false) => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
    }
    if (speechEventsRef.current) {
      speechEventsRef.current.stop();
      speechEventsRef.current = null;
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      console.log('Abort signal sent');
    }
    setText('');
    sendLogInChunks();
    isStopped.current = true;
    isCut.current = false;
    isInitialized.current = false;
    isRegistering.current = false;
    isProcessingRef.current = false;
    isProcessingTTS.current = false;
    setIsPlaying(false);
    audioChunksRef.current = [];
    registrationInformation.current = {};
    setIsLoading(false);
    setLongLoadText('');
    if (!cleanup) {
      setIsRecording(false);
    }
  };

  const handleTTSResponse = async (message: string, signal: AbortSignal) => {
    if (checkCutandStop(isCut, isStopped)) {
      throw new Error('Function stops because of isCut or isStopped');
    }

    try {
      if (isProcessingTTS.current) {
        // console.log('Waiting for current TTS processing to finish...');
        await new Promise<void>((resolve) => {
          const checkIsProcessingTTS = setInterval(() => {
            if (!isProcessingTTS.current) {
              clearInterval(checkIsProcessingTTS);
              resolve();
            }
          }, 100);
        });
      }
      isProcessingTTS.current = true;
      chatHistory.current.push({ client: `${message}` })
      userLog.current += `Client: ${message}\n---------------------------\n`

      setText(message);
      // isProcessingTTS.current = false;
      // isProcessingRef.current = false;
      // setIsLoading(false);

      const splitMessage = splitInputPerSentence(message);
      const startTime = performance.now();
      if (splitMessage.length > 1) {
        const splitProcessedMessage = splitMessage.map(str => replaceNumbersWithIndonesian(str));
        await sendResultForTTSPython(splitProcessedMessage, isCut, isStopped,
          isProcessingRef, isProcessingTTS, setIsPlaying, setIsLoading);
      } else {
        const ProcessedMessage = replaceNumbersWithIndonesian(message)
        await sendResultForTTS(ProcessedMessage, signal, isCut, isStopped,
          isProcessingRef, isProcessingTTS, setIsPlaying, setIsLoading);
      }
      const endTime = performance.now();
      console.log(`sendResultForTTS took ${(endTime - startTime) / 1000} seconds`);

      return ""

    } catch (error) {
      console.error('Error handling TTS response:', (error as Error).message);
      throw error;
    }
  };

  const handleRecordButtonClick = () => {
    if (recordState) {
      setRecordState(false);
    } else {
      setIsLoading(true);
      isStopped.current = false;
      setRecordState(true);
    }
  };

  return (
    <BrowserRouter>
      <div className="RecorderComponent">
        <header className="RecorderComponent-header">
          <button onClick={handleRecordButtonClick} className="RecorderComponent-button">
            {recordState ? 'Stop Recording' : 'Start Recording'}
          </button>
          {isLoading && <p style={{ color: '#e6e6e6' }}>{longLoadText}</p>}
          {isLoading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>) : (
            <>
              {!isLoading && <p style={{ color: '#e6e6e6' }}>{text}</p>}
              {isPlaying && <img src={WaveAnimation} style={{ width: '300px', height: '100px' }} alt="GIF animation" />}
            </>
          )}
        </header>
      </div>
    </BrowserRouter >
  );
};

export default RecorderComponent;


