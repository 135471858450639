import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export function generateShortUuid(length: number = 10): string {
  const uuid = uuidv4(); // Generate a standard UUID
  return uuid.replace(/-/g, '').substring(0, length); // Remove hyphens and trim to 10 characters
}

export function checkCutandStop(
  isCut: React.RefObject<boolean>,
  isStopped: React.RefObject<boolean>): boolean {
  const cutValue = isCut.current ?? false;
  const stoppedValue = isStopped.current ?? false;

  return cutValue || stoppedValue;
}

export const transcribeAudio = async (audioBlob: Blob, signal: AbortSignal,
  isCut: React.RefObject<boolean>, isStopped: React.RefObject<boolean>): Promise<string> => {

  if (checkCutandStop(isCut, isStopped)) {
    return ""
  }

  const formData = new FormData();
  const password = process.env.REACT_APP_PASSWORD;
  if (!password) {
    throw new Error('Password is not defined');
  }

  formData.append('file', audioBlob, 'audio.mp3');

  try {
    const response = await axios.post(`https://whisper-temp.serenic.ai/transcribe?password=${password}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal,
    });

    if (response.status !== 200) {
      throw new Error(`Error: ${response.status}`);
    }

    if (checkCutandStop(isCut, isStopped)) {
      return ""
    }

    return response.data.transcript; // Adjust according to the actual response structure
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
    } else {
      console.error('Error sending audio for transcription:', (error as Error).message);
    }
    throw error;
  }
};

export function replaceNumbersWithIndonesian(input: string): string {
  // Mapping for each digit to its Indonesian pronunciation
  const indonesianNumbers: { [key: string]: string } = {
    '0': 'nol',
    '1': 'satu',
    '2': 'dua',
    '3': 'tiga',
    '4': 'empat',
    '5': 'lima',
    '6': 'enam',
    '7': 'tujuh',
    '8': 'delapan',
    '9': 'sembilan',
  };

  // Regular expression to find numbers that are either more than 2 digits or start with '0'
  return input.replace(/\b(0\d+|\d{3,})\b/g, (number) => {
    // Split the number into individual digits and map each digit to its Indonesian equivalent
    const indonesianPronunciation = number
      .split('')
      .map(digit => indonesianNumbers[digit])
      .join(' '); // Join with space to separate the pronunciations
    
    return indonesianPronunciation;
  });
}

export function numberToIndonesian(phoneNumber: string): string {
  const numberMap: { [key: string]: string } = {
    '0': 'nol',
    '1': 'satu',
    '2': 'dua',
    '3': 'tiga',
    '4': 'empat',
    '5': 'lima',
    '6': 'enam',
    '7': 'tujuh',
    '8': 'delapan',
    '9': 'sembilan'
  };

  return phoneNumber.split('').map(char => {
    return numberMap.hasOwnProperty(char) ? numberMap[char] : char;
  }).join(' ');
}

// export function splitInputPerSentence(input: string, totalSplit: number = 4): string[] {
//   const tokens: string[] = input.match(/\S+/g) || [];
//   const totalWords = tokens.length;

//   // Ensure the input has at least 15 words
//   if (totalWords < 20) {
//     return [input];
//   }

//   const splitSentences: string[] = [];
//   const wordsPerSentence = Math.ceil(totalWords / totalSplit);

//   let startIndex = 0;

//   for (let i = 0; i < totalSplit; i++) {
//     const endIndex = startIndex + wordsPerSentence;
//     let sentence = tokens.slice(startIndex, endIndex).join(' ');

//     // Check if it ends with a comma or period
//     if (i < totalSplit - 1) {
//       if (!sentence.endsWith(',')) {
//         sentence += ',';
//       }
//     } else {
//       if (!sentence.endsWith('.')) {
//         sentence += '.';
//       }
//     }

//     splitSentences.push(sentence);
//     startIndex = endIndex;
//   }

//   return splitSentences;
// }

export function splitInputPerSentence(input: string, totalSplit: number = 4): string[] {
  const tokens = input.match(/\S+/g) || [];
  const totalWords = tokens.length;

  // Ensure the input has at least 20 words
  if (totalWords < 20) {
    return [input];
  }

  const splitSentences: string[] = [];
  let currentSentence: string[] = [];
  let wordCount = 0;

  tokens.forEach((token, index) => {
    currentSentence.push(token);
    wordCount++;

    const isEndOfSentence = token.endsWith(',') || token.endsWith('.') || token.endsWith(':') || token.endsWith('!') || token.endsWith('?');
    const isLastToken = index === tokens.length - 1;

    // If the current sentence has fewer than 6 words, keep adding tokens
    if (wordCount < 6 && !isLastToken) {
      return;
    }

    // If it's the end of a sentence or the last token, push the current sentence
    if (isEndOfSentence || isLastToken) {
      if (isLastToken && wordCount < 6 && splitSentences.length > 0) {
        // If the last segment is too short, combine it with the previous one
        splitSentences[splitSentences.length - 1] += ' ' + currentSentence.join(' ');
      } else if (wordCount >= 6 || isLastToken) {
        // Add the current sentence if it has enough words or it's the last token
        splitSentences.push(currentSentence.join(' '));
      }

      // Reset for the next sentence
      currentSentence = [];
      wordCount = 0;
    }
  });

  return splitSentences;
}

export const parseInputPrompt = async (input: string) => {
  const messages: { role: string, content: string }[] = [];
  const regex = /(User Input|AI Output):\n([\s\S]*?)/g;

  let match: RegExpExecArray | null;
  let lastIndex = 0;

  while ((match = regex.exec(input)) !== null) {
    const role = match[1];
    const content = match[2].trim();

    // Add system content if there's any text between the end of the last match and the start of the current match
    if (match.index > lastIndex) {
      const systemContent = input.slice(lastIndex, match.index).trim();
      if (systemContent) {
        messages.push({ role: "system", content: systemContent });
      }
    }

    if (role === "User input") {
      messages.push({ role: "user", content });
    } else if (role === "AI Output") {
      messages.push({ role: "assistant", content });
    }

    lastIndex = regex.lastIndex;
  }

  // Add any remaining system content after the last match
  if (lastIndex < input.length) {
    const remainingSystemContent = input.slice(lastIndex).trim();
    if (remainingSystemContent) {
      messages.push({ role: "system", content: remainingSystemContent });
    }
  }

  return messages;
};

// Python
export const sendResultForTTSPython = async (
  resultInput: string[],
  isCut: React.RefObject<boolean>,
  isStopped: React.RefObject<boolean>,
  isProcessingRef: React.MutableRefObject<boolean>,
  isProcessingTTS: React.MutableRefObject<boolean>,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (checkCutandStop(isCut, isStopped)) {
    return;
  }
  const worker = new Worker(new URL('./worker.js', import.meta.url));
  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  let audioBuffers: AudioBuffer[] = [];
  let isPlaying = false
  let isProcessing = false
  let currentPlayedIndex = 0;

  const playAudioBuffer = (audioBuffer: AudioBuffer) => {
    return new Promise<void>((resolve) => {
      isPlaying = true
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);

      const checkInterval = setInterval(() => {
        if (isCut.current || isStopped.current) {
          source.stop();
          source.disconnect();
          clearInterval(checkInterval);
          resolve();
        }
      }, 100);

      source.onended = () => {
        isPlaying = false
        clearInterval(checkInterval);
        resolve();
      };

      source.start();
    });
  };

  const processAudio = async (index: number) => {
    if (isCut.current ||
      isStopped.current ||
      (index >= resultInput.length && !isPlaying)) {
      return;
    }

    const filename = `/audio/splitTTS/trimmed_sentence_${index}.mp3`;
    worker.postMessage({ filename });
    isProcessing = true

    worker.onmessage = async (e: MessageEvent<{ type: string; arrayBuffer?: ArrayBuffer; error?: string }>) => {
      if (e.data.type === 'audio' && e.data.arrayBuffer) {
        try {
          const buffer = await audioContext.decodeAudioData(e.data.arrayBuffer);
          if (!audioBuffers[currentPlayedIndex]) {
            // console.log(`Pushed file: ${filename}`);
            audioBuffers.push(buffer);
          }
          isProcessing = false
        }
        catch {
          isProcessing = false
        }

      } else if (e.data.type === 'error' && e.data.error) {
        isProcessing = false
        console.log('Worker error:', e.data.error);
      }
    };

    if (audioBuffers[currentPlayedIndex]) {
      if (isCut.current || isStopped.current) {
        setIsPlaying(false);
        audioBuffers = []
        worker.terminate();
        return
      } else if (currentPlayedIndex < audioBuffers.length && !isCut.current && !isStopped.current && !isPlaying) {
        setIsLoading(false);
        setIsPlaying(true);

        playAudioBuffer(audioBuffers[currentPlayedIndex]);
        currentPlayedIndex++;
      }
    }

    const postInterval = setInterval(() => {
      if (!isProcessing) {
        clearInterval(postInterval)
        if (currentPlayedIndex < resultInput.length && audioBuffers[currentPlayedIndex]) {
          processAudio(currentPlayedIndex + 1)
        } else if (currentPlayedIndex < resultInput.length) {
          processAudio(currentPlayedIndex)
        }
      }
    }, 100)
  };

  try {
    await fetch("https://api.testapp-demo.xyz/convert_text_to_speech", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ text: resultInput })
    });

    const timeout = setTimeout(() => {
      isProcessingRef.current = false;
    }, 3000);

    await processAudio(0);

    const interval = setInterval(() => {
      if (currentPlayedIndex >= resultInput.length && !isPlaying) {
        clearInterval(interval);
        clearTimeout(timeout);
        worker.terminate();
        setIsPlaying(false);
        isProcessingTTS.current = false;
        isProcessingRef.current = false;
        // console.log('Processing completed.');
      } else if (audioBuffers[currentPlayedIndex]) {
        if (isCut.current || isStopped.current) {
          audioBuffers = []
          clearInterval(interval);
          clearTimeout(timeout);
          worker.terminate();
          setIsPlaying(false);
          isProcessingTTS.current = false;
          isProcessingRef.current = false;
          console.log('Playback cut off due to isCut or isStopped.');
          return
        }
        if (currentPlayedIndex < audioBuffers.length && !isCut.current && !isStopped.current && !isPlaying) {
          setIsLoading(false);
          setIsPlaying(true);
          playAudioBuffer(audioBuffers[currentPlayedIndex]);
          currentPlayedIndex++;
        }
      }
    }, 50);

  } catch (err) {
    console.error('Error during TTS processing:', err);
    worker.terminate(); // Ensure the worker is terminated even in case of an error
  }
};

// Normal
export const sendResultForTTS = async (
  result: string,
  signal: AbortSignal,
  isCut: React.RefObject<boolean>,
  isStopped: React.RefObject<boolean>,
  isProcessingRef: React.MutableRefObject<boolean>,
  isProcessingTTS: React.MutableRefObject<boolean>,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (checkCutandStop(isCut, isStopped)) {
    return
  }

  const voiceID = process.env.REACT_APP_ELEVENLABS_VOICE_ID;
  const apiKey = process.env.REACT_APP_ELEVENLABS_API_KEY;
  if (!voiceID || !apiKey) {
    console.error('Voice ID or API key is missing.');
    return;
  }
  result = result.replace(/\n/g, '');
  const options = {
    method: 'POST',
    headers: {
      'xi-api-key': apiKey,
      'Content-Type': 'application/json'
    },
    body: `{"model_id":"eleven_turbo_v2_5","text":"${result}"}`
  };
  try {
    signal.addEventListener('abort', () => {
      console.log('Abort signal received.');
      source.stop();
      source.disconnect();
    });
    let startTime = performance.now();
    const response = await fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voiceID}?optimize_streaming_latency=1`, options);

    if (!response.body) {
      throw new Error('ReadableStream not supported in this browser.');
    }

    setIsLoading(false);

    let timeout = setTimeout(() => {
      isProcessingRef.current = false;
    }, 3000);

    const checkFlagsAndStop = () => {
      if (isCut.current || isStopped.current) {
        console.log('Playback cut off due to isCut or isStopped.');
        clearInterval(checkInterval);
        source.stop();
        source.disconnect();
      }
    };

    const audioBlob = await response.blob();
    const arrayBuffer = await audioBlob.arrayBuffer();
    const audioContext = new AudioContext();
    const decodedAudioData = await audioContext.decodeAudioData(arrayBuffer);
    const source = audioContext.createBufferSource();
    source.buffer = decodedAudioData;
    source.connect(audioContext.destination);

    let endTime = performance.now();
    console.log(`Audio started playing after ${(endTime - startTime) / 1000} seconds`);
    setIsPlaying(true);
    source.start(0);
    const checkInterval = setInterval(checkFlagsAndStop, 50);

    source.onended = () => {
      clearInterval(checkInterval);
      clearTimeout(timeout);
      setIsPlaying(false);
      isProcessingTTS.current = false;
      isProcessingRef.current = false;
      // console.log("Processing completed.");
    };
  } catch (err) {
    console.error(err);
  }
};



